"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VALUE_REQUIRED = exports.UNSUPPORTED_TYPE = exports.INVALID_GUID = void 0;
// Constant message for the GUID class.
const INVALID_GUID = 'Invalid Guid value! Please specify a valid value!';
exports.INVALID_GUID = INVALID_GUID;
const VALUE_REQUIRED = 'The parameter value required!';
exports.VALUE_REQUIRED = VALUE_REQUIRED;
const UNSUPPORTED_TYPE = 'UnSupported type! please specify one of the following: [Guid | string | Uint8Array]';
exports.UNSUPPORTED_TYPE = UNSUPPORTED_TYPE;
